// Note: Heavily influenced by https://github.com/aws-amplify/amplify-js/blob/master/packages/auth/src/OAuth/OAuth.ts

import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";
import WordArray from "crypto-js/lib-typedarrays";
import Cookies from "universal-cookie";
import { NextApiRequest } from "next";

export async function StartOAuthFlow() {
  const cookies = new Cookies();

  const state = _generateState(32);
  cookies.set("lwa_state", state, { path: "/" });

  const pkce_key = _generateRandom(128);
  cookies.set("lwa_pkce", pkce_key, { path: "/" });

  const code_challenge = _generateChallenge(pkce_key);
  const code_challenge_method = "S256";

  const queryString = Object.entries({
    redirect_uri: process.env.NEXT_PUBLIC_LWA_REDIRECT_URI,
    response_type: "code",
    client_id: process.env.NEXT_PUBLIC_LWA_CLIENT_ID as string,
    scope: "profile",
    state,
    code_challenge,
    code_challenge_method,
  })
    .map(
      ([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v as string)}`
    )
    .join("&");

  const url = `https://www.amazon.com/ap/oa?${queryString}`;

  const windowProxy = window.open(url, "_self");
  if (windowProxy) {
    return Promise.resolve(windowProxy);
  } else {
    return Promise.reject();
  }
}

export async function CompleteOAuthFlow(req: NextApiRequest) {
  const expectedState = req.cookies.lwa_state;
  const actualState = req.query.state;
  if (!expectedState || !actualState || expectedState !== actualState) {
    throw new Error("bad oauth state");
  }

  const oAuthTokenEndpoint = `https://api.amazon.com/auth/o2/token`;

  const oAuthTokenBody = {
    grant_type: "authorization_code",
    code: req.query.code as string,
    client_id: process.env.NEXT_PUBLIC_LWA_CLIENT_ID as string,
    redirect_uri: process.env.NEXT_PUBLIC_LWA_REDIRECT_URI,
    code_verifier: req.cookies.lwa_pkce as string,
  };

  const body = Object.entries(oAuthTokenBody)
    .map(
      ([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v as string)}`
    )
    .join("&");

  const response = await fetch(oAuthTokenEndpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body,
  });
  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }

  const { access_token } = await response.json();

  const profileResponse = await fetch("https://api.amazon.com/user/profile", {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  if (!profileResponse.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }

  const profile = await profileResponse.json();

  return {
    externalIdpId: profile.user_id,
    email: profile.email,
    name: profile.name,
  };
}

function _generateState(length: number) {
  let result = "";
  let i = length;
  const chars =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  for (; i > 0; --i)
    result += chars[Math.round(Math.random() * (chars.length - 1))];
  return result;
}

function _generateChallenge(code: string) {
  return _base64URL(sha256(code));
}

function _base64URL(wordArray: WordArray) {
  return wordArray
    .toString(Base64)
    .replace(/=/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_");
}

function _generateRandom(size: number) {
  const CHARSET =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
  const buffer = new Uint8Array(size);
  if (typeof window !== "undefined" && !!window.crypto) {
    window.crypto.getRandomValues(buffer);
  } else {
    for (let i = 0; i < size; i += 1) {
      buffer[i] = (Math.random() * CHARSET.length) | 0;
    }
  }
  return _bufferToString(buffer);
}

function _bufferToString(buffer: Uint8Array) {
  const CHARSET =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const state = [];
  for (let i = 0; i < buffer.byteLength; i += 1) {
    const index = buffer[i] % CHARSET.length;
    state.push(CHARSET[index]);
  }
  return state.join("");
}
