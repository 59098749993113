import { Settings } from "./settings";

export class AuthUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  name: string;
  theme: string;
  loginWithAmazon: boolean;

  constructor(cognitoUser: any) {
    if (!cognitoUser) {
      throw new Error("error constructing auth user");
    }
    this.id = cognitoUser?.attributes?.sub;
    this.email = cognitoUser?.attributes?.email;
    this.firstName = cognitoUser?.attributes?.given_name;
    this.lastName = cognitoUser?.attributes?.family_name;
    this.name = cognitoUser?.attributes?.name;
    this.theme = cognitoUser?.attributes?.["custom:theme"] ?? "system";
    if (cognitoUser?.attributes?.identities) {
      const identities = JSON.parse(cognitoUser?.attributes?.identities);
      this.loginWithAmazon = identities.some(
        (identity: any) => identity?.providerType === "LoginWithAmazon"
      );
    } else {
      this.loginWithAmazon = false;
    }
  }

  getName() {
    return getName(this.firstName, this.lastName, this.name);
  }

  getFirstLetter() {
    return getFirstLetter(this.getName());
  }
}

export type UserRole = "owner" | "admin";

export class DBUser {
  id: string;
  accountId: string;
  email: string;
  firstName: string;
  lastName: string;
  name: string;
  userRole: UserRole;
  isAdmin: boolean;
  settings: Settings;
  createdAt: Date;
  updatedAt: Date;
  intercomUserHash: string | null;

  constructor(dbModel: any) {
    if (!dbModel) {
      throw new Error("error constructing db user");
    }
    this.id = dbModel?.id;
    this.accountId = dbModel?.account_id;
    this.email = dbModel?.email;
    this.firstName = dbModel?.first_name;
    this.lastName = dbModel?.last_name;
    this.name = dbModel?.name;
    this.userRole = dbModel?.user_role;
    this.isAdmin = dbModel?.is_admin;
    this.settings = new Settings(dbModel?.settings);
    this.createdAt = new Date(dbModel?.created_at);
    this.updatedAt = new Date(dbModel?.updated_at);
    this.intercomUserHash = dbModel?.intercom_user_hash ?? null;
  }

  getName() {
    return getName(this.firstName, this.lastName, this.name);
  }

  getFirstLetter() {
    return getFirstLetter(this.getName());
  }
}

export function getName(firstName: string, lastName: string, name: string) {
  let from = "User";
  if (firstName && firstName.length > 0) {
    from = firstName;
    if (lastName && lastName.length > 0) {
      from += " " + lastName;
    }
  } else if (name && name.length > 0) {
    from = name;
  }

  return from
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
}

export function getFirstLetter(name: string) {
  return name.charAt(0).toUpperCase();
}

const roleMap: { [key: string]: number } = {
  owner: 2,
  admin: 1,
  // TODO: other role
};

export function compareRoles(role1: UserRole, role2: UserRole) {
  return roleMap[role1] - roleMap[role2];
}
