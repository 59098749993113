// The configuration required for Amplify. Check Vercel for the process.env vars
// See documentation here: https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/#general-configuration
const amplifyConfig = {
  Auth: {
    // AWS Region
    region: process.env.NEXT_PUBLIC_AWS_REGION,
    // AWS Cognito User Pool ID
    userPoolId: process.env.NEXT_PUBLIC_AWS_COGNITO_USER_POOL_ID,
    // AWS Cognito User Pool Client ID (of which there can be multiple)
    userPoolWebClientId:
      process.env.NEXT_PUBLIC_AWS_COGNITO_USER_POOL_CLIENT_ID,
    oauth: {
      // AWS Cognito User Pool Domain (which we redirect to for Login with Amazon during signIn)
      domain: process.env.NEXT_PUBLIC_AWS_COGNITO_USER_POOL_DOMAIN,
      // The Aura location to redirect back to on sign in
      redirectSignIn: process.env.NEXT_PUBLIC_REDIRECT_SIGN_IN,
      // The Aura location to redirect back to on sign out
      redirectSignOut: process.env.NEXT_PUBLIC_REDIRECT_SIGN_OUT,
      // Use the code OAuth flow
      responseType: "code",
    },
    cookieStorage: {
      // What domain to store the cookie under
      domain:
        process.env.NODE_ENV === "development"
          ? typeof window !== "undefined"
            ? window.location.hostname
            : "localhost"
          : "goaura.com",
      // The cookie should be available on every path in the domain
      path: "/",
      // The cookie expires after a year (however the actual Cognito refresh token will expire before then)
      expires: 365,
      // Allows us to use the cookies in development
      sameSite: "lax",
      // Ditto
      secure: false,
    },
  },
  ssr: true, // Required when using an SSR library like Next.js
};

export default amplifyConfig;
