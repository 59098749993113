import { useLocalStorage } from "./useLocalStorage";

export const useSegmentWriteKey = () =>
  useLocalStorage(
    "segment_write_key",
    process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY
  );

export const useSegmentCDNUrl = () =>
  useLocalStorage("segment_cdn_url", "https://cdn.segment.com");
