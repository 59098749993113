import { useRouter } from "next/router";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

export type Breadcrumb = {
  crumb: string;
  path?: string;
};

const BreadcrumbContext = createContext<{
  breadcrumbs: Breadcrumb[];
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void;
}>({
  breadcrumbs: [],
  setBreadcrumbs: () => {},
});

export const useBreadcrumbs = () => {
  return useContext(BreadcrumbContext);
};

export function BreadcrumbProvider({ children }: { children: ReactNode }) {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);

  const router = useRouter();

  const clearBreadcrumbs = useCallback(() => {
    setBreadcrumbs([]);
  }, [setBreadcrumbs]);

  useEffect(() => {
    clearBreadcrumbs();
  }, [clearBreadcrumbs, router.pathname]);

  return (
    <BreadcrumbContext.Provider
      value={{
        breadcrumbs,
        setBreadcrumbs,
      }}
    >
      {children}
    </BreadcrumbContext.Provider>
  );
}
